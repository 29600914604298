<template>
    <survey-loader v-if="!loaded" />
    <report v-else id="report-controller" class="psi">
        <cover-page :name="user" :date="$filters.date(reportData.dateCompleted)" />

        <page>
            <content-wrapper>
                <survey-translate base="reportsWeb" name="INTRO_START" :args="{ participantName: user, context }" raw />
                <survey-title v-t="'reportsWeb.INTRO_TITLE'" />
                <survey-translate tag="p" base="reportsWeb" name="INTRO_TEASER" raw />
            </content-wrapper>

            <content-wrapper inverse>
                <personal-chart :categories="benchmarkPrimary.categories" hide-iteration />
            </content-wrapper>
        </page>

        <page>
            <survey-subtitle v-t="{ path: 'reportsWeb.GENERAL_TITLE', args: { context } }" />
            <content-wrapper inverse>
                <survey-translate
                    base="reportsWeb"
                    name="GENERAL_SCORE_DESC"
                    :args="{
                        context,
                        personalScore: general.personalScore,
                        teamMedian: general.teamMedian,
                        positiveAvg: general.positiveAvg,
                        negativeAvg: general.negativeAvg,
                    }"
                    raw
                />
            </content-wrapper>

            <bar-chart-wrapper :category-key="CategoryType.General" show-quartiles hide-iteration-picker>
                <bar-chart :scores="scores(benchmarkPrimary, CategoryType.General)" primary />
                <bar-chart :scores="scores(secondary, CategoryType.General)" />
            </bar-chart-wrapper>

            <content-wrapper>
                <survey-translate tag="p" base="reportsWeb" :name="`GENERAL_1_QUARTILE_${general.psiQuartile.toUpperCase()}`" :args="{ context }" raw />
                <survey-translate tag="p" base="reportsWeb" name="GENERAL_EXPLANATION" raw />
            </content-wrapper>

            <content-wrapper class="fearless-leadership">
                <survey-translate class="survey-subtitle" tag="h2" base="reportsWeb" :name="`LEADERSHIP_TITLE_GENERAL`" raw />
                <survey-translate tag="p" base="reportsWeb" :name="`${leadershipStatus(general)}`" :args="{ context }" raw />
            </content-wrapper>
        </page>

        <template v-for="key in categoryKeys" :key="`cat_${key}`">
            <page break-before>
                <category
                    :name="CategoryType[key]"
                    report="reportsWeb"
                    :primary="benchmarkPrimary"
                    :secondary="secondary"
                    :context="context"
                    hide-iteration-picker
                    single-description
                >
                    <template v-slot:charts>
                        <bar-chart :scores="scores(benchmarkPrimary, CategoryType[key])" primary />
                        <bar-chart :scores="scores(secondary, CategoryType[key])" />
                    </template>
                </category>
                <content-wrapper class="fearless-leadership">
                    <survey-translate class="survey-subtitle" tag="h2" base="reportsWeb" :name="`LEADERSHIP_TITLE_${CategoryType[key]}`" raw />
                    <survey-translate
                        tag="p"
                        base="reportsWeb"
                        :name="`${leadershipStatus(scores(benchmarkPrimary, CategoryType[key]))}`"
                        :args="{ context }"
                        raw
                    />
                </content-wrapper>
            </page>
        </template>

        <page break-before>
            <survey-title v-t="{ path: 'reportsWeb.GENERAL_CONCLUSION_TITLE', args: { context } }" />
            <content-wrapper>
                <survey-translate
                    tag="p"
                    base="reportsWeb"
                    :name="`CONCLUSION_${leadershipStatus(general)}`"
                    raw
                />
            </content-wrapper>
        </page>
    </report>
</template>
<script src="./PsiCoaching.ts"></script>
