import BaseReportComponent from '@/report/components/BaseReportComponent';
import { computed, ComputedRef, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { CtaBasicReportData } from '../models/CtaBasicReportData';

export default defineComponent({
    extends: BaseReportComponent,
    components: {},
    setup() {
        const store = useStore();
        const reportData: ComputedRef<CtaBasicReportData> = computed(() => store.getters['report/report']);

        return {
            reportData,
        };
    },
});
